



































import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import { Card, Dropdown, ActionMenu, Tooltip, HttpGet, HttpPost, DataTable, DataTableColumn, Searchbox, DeleteConfirmationModal, Action, CopyAsync, Badge, BasicCopy } from '../../../lib/mavejs'
import { url } from '@/functions'
import CampaignTypeBadge from '@/components/CampaignTypeBadge.vue'
import InlineErrors from '@/components/InlineErrors.vue'
import GeoCopy from '@/components/GeoCopy.vue'

@Component({
  components: { GeoCopy, Dropdown, ActionMenu, Tooltip, InlineErrors, CampaignTypeBadge, Card, HttpGet, HttpPost, DataTable, DataTableColumn, Searchbox, DeleteConfirmationModal, Action, CopyAsync, Badge, BasicCopy },
  methods: { url }
})

export default class CompareNames extends Vue {
  options = []
}

